export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: '首页',
      meta: {
        icon: 'layui-icon-chart-screen',
      },
    },

    {
      name: 'userdata',
      displayName: '用户数据',
      meta: {
        icon: 'layui-icon-friends',
      },
    },

    {
      name: 'UserData_loginwxlist',
      displayName: '用户-登录数据',
      meta: {
        icon: 'layui-icon-username',
      },
    },
    {
      name: 'UserData_openwxlist',
      displayName: '用户-打开数据',
      meta: {
        icon: 'layui-icon-username',
      },
    },

    {
      name: '404',
      displayName: '',
      meta: {
        icon: 'group123',
      },
    },

    {
      name: 'duanju',
      displayName: '短剧管理(微信提供数据)',
      meta: {
        icon: 'layui-icon-slider',
      },
    },

    {
      name: 'kefu',
      displayName: '客服系统 (微信公众平台)',
      meta: {
        icon: 'layui-icon-login-wechat',
      },
    },
    {
      name: 'we',
      displayName: '小程序分析 (微信公众平台)',
      meta: {
        icon: 'layui-icon-login-wechat',
      },
    },

    {
      name: '404',
      displayName: '',
      meta: {
        icon: 'group123',
      },
    },

    {
      name: 'keyword',
      displayName: '搜索页面-猜你想要',
      meta: {
        icon: 'layui-icon-search',
      },
    },

    {
      name: 'index-page-settings',
      displayName: '首页(banner,新剧，热播)设置',
      meta: {
        icon: 'layui-icon-cellphone-fine',
      },
      children: [
        {
          name: 'banner',
          displayName: 'banner',
        },
        {
          name: 'xinju',
          displayName: '新剧推荐',
        },
        {
          name: 'remen',
          displayName: '热播推荐',
        },
      ],
    },

    {
      name: 'keyword',
      displayName: '剧场页面（待开发）',
      meta: {
        icon: 'layui-icon-video',
      },
    },

    {
      name: 'dzsc',
      displayName: '点赞 &  收藏    记录',
      meta: {
        icon: 'layui-icon-heart',
      },
      children: [
        {
          name: 'DJ_dianzan',
          displayName: '点赞记录',
        },
        {
          name: 'DJ_shoucang',
          displayName: '收藏记录',
        },
      ],
    },

    {
      name: 'DJ_shoumai',
      displayName: '我的-观看数据',
      meta: {
        icon: 'layui-icon-rmb',
      },
    },

    {
      name: '404',
      displayName: '',
      meta: {
        icon: 'group123',
      },
    },

    {
      name: 'login',
      displayName: '重新登录',
      meta: {
        icon: 'layui-icon-lock',
      },
    },
    {
      name: '404',
      displayName: '404页面',
      meta: {
        icon: 'layui-icon-face-cry',
      },
    },
    {
      name: 'settings',
      displayName: '设置',
      meta: {
        icon: 'layui-icon-set',
      },
    },
  ] as INavigationRoute[],
}

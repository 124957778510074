import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'userdata',
        path: 'userdata',
        component: () => import('../pages/usersdata/UsersData.vue'),
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },

      {
        name: 'UserData_loginwxlist',
        path: 'UserData_loginwxlist',
        component: () => import('../pages/usersdata_loginwxlist/UsersPage.vue'),
      },
      {
        name: 'UserData_openwxlist',
        path: 'UserData_openwxlist',
        component: () => import('../pages/usersdata_openwxlist/UsersPage.vue'),
      },

      {
        name: 'duanju',
        path: 'duanju',
        component: () => import('../pages/duanju/duanju.vue'),
      },

      {
        name: 'kefu',
        path: 'kefu',
        component: () => import('../pages/kefu/kefu.vue'),
      },
      {
        name: 'we',
        path: 'we',
        component: () => import('../pages/we/we.vue'),
      },

      {
        name: 'keyword',
        path: 'keyword',
        component: () => import('../pages/keyword/keyword.vue'),
      },
      {
        name: 'banner',
        path: 'banner',
        component: () => import('../pages/index-page-settings/banner.vue'),
      },

      {
        name: 'xinju',
        path: 'xinju',
        component: () => import('../pages/index-page-settings/xinju.vue'),
      },

      {
        name: 'remen',
        path: 'remen',
        component: () => import('../pages/index-page-settings/remen.vue'),
      },

      {
        name: 'DJ_dianzan',
        path: 'DJ_dianzan',
        component: () => import('../pages/dzsc/DJ_dianzan.vue'),
      },
      {
        name: 'DJ_shoucang',
        path: 'DJ_shoucang',
        component: () => import('../pages/dzsc/DJ_shoucang.vue'),
      },
      {
        name: 'DJ_shoumai',
        path: 'DJ_shoumai',
        component: () => import('../pages/DJ_shoumai/DJ_shoumai.vue'),
      },

      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
    ],
  },

  // {
  //   path: '/auth',
  //   component: AuthLayout,
  //   children: [
  //     {
  //       name: 'login',
  //       path: 'login',
  //       component: () => import('../pages/auth/Login.vue'),
  //     },
  //     {
  //       name: 'signup',
  //       path: 'signup',
  //       component: () => import('../pages/auth/Signup.vue'),
  //     },
  //     {
  //       name: 'recover-password',
  //       path: 'recover-password',
  //       component: () => import('../pages/auth/RecoverPassword.vue'),
  //     },
  //     {
  //       name: 'recover-password-email',
  //       path: 'recover-password-email',
  //       component: () => import('../pages/auth/CheckTheEmail.vue'),
  //     },
  //     {
  //       path: '',
  //       redirect: { name: 'login' },
  //     },
  //   ],
  // },

  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('../pages/Login2.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('kailuo-login')

  if (token == new Date().toLocaleDateString() || to.path === '/login') {
    next()
  } else {
    next('/login')
  }
})

export default router
